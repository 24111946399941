export async function authorize({ application, projectId, country }, firebaseUser, authBackend) {
  if (!application || !projectId || !country) throw new Error('application, projectId and country must be specified')

  const idToken = await firebaseUser.getIdToken(true)

  const headers = new Headers({
    Authorization: `Bearer ${idToken}`,
    application,
    project_id: projectId,
    country
  })

  return fetch(authBackend, {
    headers,
    method: 'GET',
    cache: 'default',
    mode: 'cors'
  }).then(response => {
    if (!response.ok) {
      return response.json().then(message => {
        if (response.status === 403) {
          throw { user: message, status: 403 }
        }
        throw message
      })
    }
    return response.json().then(async user => {
      const roles = await firebaseUser.getIdTokenResult(true).then(idTokenResult => idTokenResult.claims.roles)

      return {
        user,
        roles
      }
    })
  })
}
