import 'firebase/auth'
import { authorize } from '@/auth/authorizations'
import { initializeApp } from 'firebase/app'
import * as firebaseui from 'firebaseui'
import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  application: process.env.VUE_APP_APP_NAME,
  country: process.env.VUE_APP_COUNTRY
}

export let globalProperties = {}
initializeApp(config)

const ui = new firebaseui.auth.AuthUI(getAuth())
const auth = getAuth()

const customToken = new URLSearchParams(window.location.search).get('ct')

onAuthStateChanged(auth, async firebaseUser => {
  if (firebaseUser) {
    authorize(config, firebaseUser, process.env.VUE_APP_BACKEND_SMART_AUTH)
      .then(({ user, roles }) => {
        globalProperties = { firebaseUser, user, roles }
        import(/* webpackChunkName: "createApp" */ './bootstrapApp').then(createApp => {
          createApp.default({ firebaseUser, user, roles })
        })
      })
      .catch(reason => {
        console.warn(reason)
        if (reason.status === 403) {
          window.location.href = '/forbidden.html'
        } else {
          window.location.href = '/error.html'
        }
      })
  } else {
    if (customToken) {
      signInWithCustomToken(auth, customToken)
    } else {
      ui.start('#firebase-ui', {
        signInFlow: 'popup',
        signInSuccessUrl: '/',
        signInOptions: [
          {
            provider: 'oidc.pass',
            buttonColor: '#E0001A',
            iconUrl: 'null',
            fullLabel: 'Se connecter à ',
            providerName: 'datastore'
          }
        ]
      })
    }
  }
})
